<template>
<div>

    <v-row align="center" class=" mb-3">
        <!-- <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class="btn" block>
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3> إنشاء شحنة جديدة</h3>
            </v-btn>
        </v-col> -->
        <v-spacer />

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.customerPhoneNumber" label="بحث حسب رقم الهاتف" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.no" label="بحث حسب رقم الشحنة" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.customerName" label="بحث حسب اسم الزبون" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.merchantName" label="بحث حسب اسم التاجر" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.delegaterName" label="بحث حسب اسم المندوب" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.packageContent" label="بحث حسب المحتوى" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <aAutoComplete v-model="filter.warehouseId" :items="warehouses" item-text="name" item-value="id" title="المخزن" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aAutoComplete v-model="filter.deliveryGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة التوصيل" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aAutoComplete v-model="filter.receivingGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة الاستلام" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aDatePicker @input="getItems()" v-model="filter.fromDate" title="بحث من تاريخ" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aDatePicker @input="getItems()" v-model="filter.toDate" title="بحث الى تاريخ" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />

        <!-- <v-col cols="12" md="1" class="ml-12">
            <v-btn @click="exportToExcel()" dark color="teal" large class="btn" :loading="loading">
                <v-icon class="ml-4" >fi fi-rr-file-excel</v-icon>
                <h3>تصدير Excel {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>

        <v-col cols="12" md="1" class="mr-12">
            <v-btn @click="exportToPdf()" dark color="error" large class="btn" :loading="loading">
                <v-icon class="ml-3">fi fi-rr-file-pdf</v-icon>
                <h3>تصدير pdf {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col> -->
    </v-row>

    <!-- <v-row>
        <v-col cols="12" md="1" class="ml-12">
            <v-btn @click="exportToExcel()" dark color="teal" large class="btn" :loading="loading">
                <v-icon class="ml-4" >fi fi-rr-file-excel</v-icon>
                <h3>تصدير Excel {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>

        <v-col cols="12" md="1" class="mr-12">
            <v-btn @click="exportToPdf()" dark color="error" large class="btn" :loading="loading">
                <v-icon class="ml-3">fi fi-rr-file-pdf</v-icon>
                <h3>تصدير pdf {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
    </v-row> -->

    <v-row>
        <v-col cols="2" v-for="(status,index) in orderStatus" :key="index">
            <v-btn class="btn " @click="()=> { currentIndex == index ? (filter.statusId = '' , getItems(), currentIndex = null) : (filter.statusId = status.id , getItems(), currentIndex = index)}" block depressed large :color="currentIndex == index ? 'primary' : $vuetify.theme.dark ? 'third' : 'white'">
                <v-icon class="ml-1" :color="status.color">{{currentIndex == index ? 'fi fi-rr-circle' : 'fi fi-rr-circle-small'}}</v-icon>
                <h3>{{status.arabicName}}</h3>
            </v-btn>
        </v-col>
    </v-row>
    <br />
    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <!-- <template v-slot:item.customerPhoneNumber="{item}">
                <div dir="ltr">{{item.customerPhoneNumber}}</div>
            </template> -->

            <template v-slot:item.order.customerName="{item}">
                <div>{{ item.order.customerName }}</div>
                <h5 dir="ltr" class="grey--text">{{item.order.customerPhoneNumber}} || {{item.order.customerPhoneNumber2}}</h5>
                <!-- <h5 dir="ltr" class="grey--text">{{item.customerPhoneNumber2}}</h5> -->
            </template>

            <template v-slot:item.order.packageContent="{item}">
                <div>{{ item.order.packageContent }} <span class="grey--text">( {{item.order.packageItemsCount}} )</span></div>
            </template>

            <!-- <template v-slot:item.customerPhoneNumber2="{item}">
                <div dir="ltr">{{item.customerPhoneNumber2}}</div>
            </template> -->

            <template v-slot:item.order.receivingGovernorate="{item}">
                <div>{{ item.order.merchant.fullName }}</div>
                <h5 class="grey--text" v-if="item.order.receivingGovernorate">{{item.order.receivingGovernorate.name}} <span v-if="item.order.receivingArea">( {{item.order.receivingArea.name}} )</span></h5>
            </template>

            <template v-slot:item.order.deliveryGovernorate="{item}">
                <div>{{ item.order.customerName }}</div>
                <h5 dir="ltr" class="grey--text">{{item.order.customerPhoneNumber}} || {{item.order.customerPhoneNumber2}}</h5>
                <h5 class="grey--text" v-if="item.order.deliveryGovernorate">{{item.order.deliveryGovernorate.name}} <span v-if="item.order.deliveryArea">( {{item.order.deliveryArea.name}} )</span></h5>
                <h5 class="grey--text">{{ item.order.deliveryAddress }}</h5>
            </template>

            <!-- <template v-slot:item.packageValue="{item}">
                <div>{{$service.formatCurrency(item.packageValue)}} </div>
            </template>

            <template v-slot:item.deliveryCost="{item}">
                <div>{{$service.formatCurrency(item.deliveryCost)}} </div>
            </template>

            <template v-slot:item.sizeCost="{item}">
                <div>{{$service.formatCurrency(item.sizeCost)}} </div>
            </template>

            <template v-slot:item.extraSize="{item}">
                <div>{{$service.formatCurrency(item.extraSize)}} </div>
            </template> -->

            <template v-slot:item.order.no="{item}">
                <div>{{ item.order.no }}</div>
                <h6 class="grey--text">{{item.created.substr(0,10)}} </h6>
            </template>

            <template v-slot:item.status="{item}">
                    <v-chip label :color="item.status.color" dark v-bind="attrs" v-on="on" v-if="item.status">
                        <h4>{{item.status.arabicName}}</h4>
                    </v-chip>
            </template>

            <template v-slot:item.order.packageItemsCount="{item}">
                <v-chip>{{item.order.packageItemsCount}}</v-chip>
            </template>

            <template v-slot:item.order.packageCost="{item}">
                <!-- قيمة الشحنة -->
                <h4 class="orange--text mt-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="orange" v-on="on" small class="mx-1">fi fi-rr-box-alt</v-icon>
                        </template>
                        <span>قيمة الشحنة</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.order.packageValue)}}
                </h4>
                <!-- كلفة التوصيل -->
                <h4 class="indigo--text">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="indigo" v-on="on" small class="mx-1">fi fi-rr-truck-side</v-icon>
                        </template>
                        <span>كلفة التوصيل</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.deliveryCost)}}
                </h4>
                <!-- الحجم الزائد -->
                <h4 class="purple--text">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="purple" v-on="on" small class="mx-1">fi fi-rr-box-open-full</v-icon>
                        </template>
                        <span>الحجم الزائد</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.sizeCost)}} ( {{ item.extraSize }} )
                </h4>
                <v-divider class="my-1"/>
                <!-- تكلفة الشحنة -->
                <h4 class="teal--text mb-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="teal" v-on="on" small class="mx-1">fi fi-rr-file-invoice</v-icon>
                        </template>
                        <span>تكلفة الشحنة</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.order.packageCost)}} 
                </h4>

                <!-- <v-chip label>{{$service.formatCurrency(item.order.packageCost)}}</v-chip> -->
            </template>

            
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
</div>
</template>

<script>
export default {

    components: {
        Dialog: () => import("./dialog"),
    },

    data() {
        return {
            loading: false,
            items: [],
            orderStatus: [],
            warehouses: [],
            currentIndex: null,
            filter: {
                statusId: '',
                customerPhoneNumber: '',
                no: '',
                customerName: '',
                warehouseId: '',
                deliveryGovernorateId: '',
                receivingGovernorateId: '',
                delegaterName: '',
                merchantName: '',
                packageContent: '',
                fromDate: '',
                toDate: '',
            },
            headers: [{
                    text: "#",
                    value: "order.no",
                    width: 100
                },
                {
                    text: "من",
                    value: "order.receivingGovernorate",
                    width: 200
                },
                {
                    text: "الى",
                    value: "order.deliveryGovernorate",
                    width: 300,
                    align: 'right'
                },
                {
                    text: "مخزن",
                    value: "warehouse.name",
                    width: 200
                },
                // {
                //     text: "المندوب",
                //     value: "order.delegater.fullName",
                //     width: 200
                // },
                {
                    text: "المحتويات",
                    value: "order.packageContent",
                    width: 200
                },
                {
                    text: "تكلفة الشحن",
                    value: "order.packageCost",
                    width: 150
                },
                {
                    text: "حالة الطلب",
                    value: "status",
                    width: 130,
                    align: 'right'
                },
                // {
                //     text: "العدد",
                //     value: "packageItemsCount",
                //     width: 100
                // },
                // {
                //     text: "رقم الهاتف",
                //     value: "customerPhoneNumber",
                //     width: 200,
                //     align: 'right'
                // },
                // {
                //     text: "رقم الهاتف2",
                //     value: "customerPhoneNumber2",
                //     width: 200,
                //     align: 'right'
                // },
                // {
                //     text: "العنوان",
                //     value: "deliveryAddress",
                //     width: 150
                // },
                // {
                //     text: "تاريخ الانشاء",
                //     value: "created",
                //     width: 150
                // },
               
            ],

        }
    },

    created() {

        this.getItems()
        this.getOrderStatus()
        this.getWarehouses()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);

    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Order/orderHistory?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&StatusId=${this.filter.statusId}&CustomerPhoneNumber=${this.filter.customerPhoneNumber}&No=${this.filter.no}&CustomerName=${this.filter.customerName}&WarehouseId=${this.filter.warehouseId}&DeliveryGovernorateId=${this.filter.deliveryGovernorateId}&ReceivingGovernorateId=${this.filter.receivingGovernorateId}&MerchantName=${this.filter.merchantName}&DelegaterName=${this.filter.delegaterName}&PackageContent=${this.filter.packageContent}&FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getAreas() {
            this.loading = true
            try {
                let res = await this.$http.get(`Area?PageIndex=1&PageSize=100&Name=${this.searchArea}`)
                this.areas = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async changeOrderStatus(statusId, orderId) {
            this.loading = true
            try {
                await this.$http.put(`Order/ChangeStatus?OrderId=${orderId}&NewStatusId=${statusId}`)
                this.getItems()
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getWarehouses() {
            this.loading = true
            try {
                let res = await this.$http.get(`Warehouse?PageIndex=1&PageSize=1000`)
                this.warehouses = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getOrderStatus() {
            this.loading = true
            try {
                let res = await this.$http.get(`OrderStatus?PageIndex=1&PageSize=100`)
                this.orderStatus = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        

    },

}
</script>
